import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HTTP } from 'src/lib/axios';
import { AppThunk } from 'src/store';
import { CallJSON } from 'src/types/calls';
import { parseData } from 'src/utils/common';

export interface AnalyticsState {
  calls: {
    loading: boolean;
    data: Array<any>;
  };
  callDetails: {
    loading: boolean;
    call: CallJSON;
  };
}

const initialState: AnalyticsState = {
  calls: {
    loading: false,
    data: []
  },
  callDetails: {
    loading: false,
    call: null
  }
};

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setCalls(state: AnalyticsState, { payload }: PayloadAction<any>): void {
      state.calls = payload;
    },
    setSpecificCall(
      state: AnalyticsState,
      { payload }: PayloadAction<any>
    ): void {
      state.callDetails = payload;
    }
  }
});

export const { reducer } = slice;

export const { setCalls, setSpecificCall } = slice.actions;

export const getCalls =
  (token: string, params = null): AppThunk =>
  async (dispatch, getState): Promise<any> => {
    const state = getState();
    dispatch(setCalls({ ...state.analytics.calls, loading: true }));

    try {
      const response = await HTTP.get('/metrics/internal/calls', {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params
      });
      const data = parseData(response.data.data);
      dispatch(setCalls({ data, loading: false }));
    } catch (error) {
      dispatch(setCalls({ ...state.analytics.calls, loading: true }));
      console.error(error);
    }
  };

export const getSpecificCall =
  (
    token: string,
    accountId: string,
    australisId: string,
    callId: string,
    params = null
  ): AppThunk =>
  async (dispatch, getState): Promise<any> => {
    const state = getState();
    dispatch(
      setSpecificCall({ ...state.analytics.callDetails, loading: true })
    );

    try {
      const response = await HTTP.get(
        `/voip/account/${accountId}/user/${australisId}/call/${callId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params
        }
      );
      const { data } = response;
      dispatch(setSpecificCall({ call: { ...data }, loading: false }));
    } catch (error) {
      dispatch(
        setSpecificCall({ ...state.analytics.callDetails, loading: true })
      );
      console.error(error);
    }
  };
