import { combineReducers } from '@reduxjs/toolkit';
import { reducer as accountReducer } from '../slices/accounts';
import { reducer as analyticsReducer } from '../slices/analytics';
import numbersReducer from '../slices/numbers';
import metaReducer from '../slices/meta';

const rootReducer = combineReducers({
  account: accountReducer,
  meta: metaReducer,
  analytics: analyticsReducer,
  numbers: numbersReducer
});

export default rootReducer;
