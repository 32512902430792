import { createContext, useEffect, useReducer } from 'react'
import { useSnackbar } from 'notistack'
import type { FC, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { Auth0Client } from '@auth0/auth0-spa-js'
import { auth0Config } from '../config'
import type { User } from '../types/user'
import { HTTP } from '../lib/axios'

let auth0Client: Auth0Client | null = null

interface State {
  isInitialized: boolean
  isAuthenticated: boolean
  user: User | null
}

export interface AuthContextValue extends State {
  platform: 'Auth0'
  loginWithPopup: (options?: any) => Promise<void>
  logout: () => void
}

interface AuthProviderProps {
  children: ReactNode
}

type InitializeAction = {
  type: 'INITIALIZE'
  payload: {
    isAuthenticated: boolean
    user: User | null
  }
}

type LoginAction = {
  type: 'LOGIN'
  payload: {
    user: User
  }
}

type LogoutAction = {
  type: 'LOGOUT'
}

type RegisterAction = {
  type: 'REGISTER'
}

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
}

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    }
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user
    }
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
}

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
)

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'Auth0',
  loginWithPopup: () => Promise.resolve(),
  logout: () => Promise.resolve()
})

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)

  const logout = (): void => {
    auth0Client.logout({ returnTo: process.env.REACT_APP_BASE_URL })
    dispatch({
      type: 'LOGOUT'
    })
  }

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        auth0Client = new Auth0Client({
          redirect_uri: window.location.origin,
          ...auth0Config
        })

        await auth0Client.checkSession()

        const isAuthenticated = await auth0Client.isAuthenticated()

        if (isAuthenticated) {
          const user = await auth0Client.getUser()

          // Here you should extract the complete user profile to make it
          // available in your entire app.
          // The auth state only provides basic information.

          const claims = await auth0Client.getIdTokenClaims()
          const token = claims.__raw
          try {
            const res = await HTTP.post('/users/auth/identify', {}, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })
            console.log(res)
            const { data } = res
            data.data.user.isCorvumAdmin = true
            if (!data.data.user.isCorvumAdmin) {
              enqueueSnackbar('You do not have permission to access this portal', {
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'top'
                },
                variant: 'error'
              })
              // logout()
            } else {
              dispatch({
                type: 'INITIALIZE',
                payload: {
                  isAuthenticated: true,
                  user: {
                    auth0Token: token,
                    id: user.sub,
                    avatar: user.picture,
                    email: user.email,
                    name: `${user.given_name} ${user.family_name}`,
                    ...data.data.user
                  }
                }
              })
            }
          } catch (error) {
            console.log(error)
            console.log(error.response)
            logout()
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: null
            }
          })
        }
      } catch (err) {
        console.error(err)
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        })
      }
    }

    initialize()
  }, [])

  const loginWithPopup = async (options): Promise<void> => {
    await auth0Client.loginWithPopup(options)

    const isAuthenticated = await auth0Client.isAuthenticated()

    if (isAuthenticated) {
      const user = await auth0Client.getUser()
      // Here you should extract the complete user profile to make it available in your entire app.
      // The auth state only provides basic information.
      const claims = await auth0Client.getIdTokenClaims()
      const token = claims.__raw
      try {
        const res = await HTTP.post('/users/auth/identify', {}, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        console.log(res)
        const { data } = res
        if (!data.data.user.isCorvumAdmin) {
          enqueueSnackbar('You do not have permission to access this portal', {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top'
            },
            variant: 'error'
          })
          logout()
        } else {
          dispatch({
            type: 'LOGIN',
            payload: {
              user: {
                auth0Token: token,
                id: user.sub,
                avatar: user.picture,
                email: user.email,
                name: `${user.given_name} ${user.family_name}`,
                ...data.data.user
              }
            }
          })
        }
      } catch (error) {
        console.log(error)
        console.log(error.response)
        logout()
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Auth0',
        loginWithPopup,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthContext
