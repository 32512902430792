export const parseData = (data: Array<string>) => {
  const parsed = [];
  if (data?.length) {
    data.forEach((row) => {
      const sanitized = row.replace('"[', '[').replace(']"', ']');
      parsed.push(JSON.parse(sanitized));
    });
  }

  return parsed;
};

export const formatNumber = (
  phoneNumber: string,
  addIntlCode = false
): string => {
  const unformattedNum = phoneNumber;
  const cleaned = unformattedNum.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [
      addIntlCode ? intlCode : '',
      '(',
      match[2],
      ') ',
      match[3],
      '-',
      match[4]
    ].join('');
  }
  return phoneNumber;
};
