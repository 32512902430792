import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { HTTP } from '../lib/axios';
import type { AppThunk } from '../store';

interface AccountState {
  loading: boolean;
  accounts: Array<any>;
  users: Array<any>;
  accountJwt: string;
  VOIPAccountDetails: any;
}

const initialState: AccountState = {
  loading: false,
  accounts: [],
  users: [],
  accountJwt: '',
  VOIPAccountDetails: {}
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccounts(state: AccountState, { payload }: PayloadAction<any>): void {
      state.accounts = payload;
    },
    setUsers(state: AccountState, { payload }: PayloadAction<any>): void {
      state.users = payload;
    },
    setAccountJwt(state: AccountState, { payload }: PayloadAction<any>): void {
      state.accountJwt = payload;
    },
    setLoading(state: AccountState, { payload }: PayloadAction<boolean>): void {
      state.loading = payload;
    },
    setVOIPAccountDetails(
      state: AccountState,
      { payload }: PayloadAction<any>
    ): void {
      state.VOIPAccountDetails = payload;
    }
  }
});

export const { reducer } = slice;

export const {
  setAccounts,
  setUsers,
  setLoading,
  setAccountJwt,
  setVOIPAccountDetails
} = slice.actions;

export const getAccounts =
  (token: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const res = await HTTP.get('/users/accounts', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const { data } = res.data;
      dispatch(setAccounts(data.Items));
      dispatch(setLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    }
  };

export const getAccountJwt =
  (token: string, accountId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const res = await HTTP.get(`/users/${accountId}/jwt`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const { data } = res.data;
      dispatch(setAccountJwt(data.token));
      dispatch(setLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    }
  };

export const getUsers =
  (token: string, accountId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const res = await HTTP.get(`/users/${accountId}/users`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const { data } = res.data;
      dispatch(setUsers(data));
      dispatch(setLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    }
  };

export const getVOIPAccountDetails =
  (token: string, accountId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const res = await HTTP.get(`/voip/account/${accountId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      dispatch(setVOIPAccountDetails(res.data.account));
    } catch (err) {
      console.error(`Error getting VOIP account details`, err);
    }
  };

export const createRingGroup =
  (
    token: string,
    accountId: string,
    australisId: string,
    ringGroup: any
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const routingTable = {
        routingTableName: ringGroup.routingTableName,
        ringGroups: ringGroup.groups,
        phoneNumber: ringGroup.phoneNumber.phoneNumber,
        voicemailGreetingId: ringGroup.voicemailGreetingId
      };

      const res = await HTTP.post(
        `/voip/account/${accountId}/user/${australisId}/routingTable`,
        routingTable,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      dispatch(setVOIPAccountDetails(res.data.voipAccount));
    } catch (err) {
      console.error(err);
    } finally {
      //
    }
  };

export const updateRingGroup =
  (
    token: string,
    accountId: string,
    australisId: string,
    routingTableId: string,
    ringGroup: any
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const routingTable = {
        routingTableName: ringGroup.routingTableName,
        ringGroup: ringGroup.groups,
        phoneNumber: ringGroup.phoneNumber.phoneNumber,
        voicemailGreetingId: ringGroup.voicemailGreetingId?.voicemailGreetingId
      };

      const res = await HTTP.post(
        `/voip/account/${accountId}/user/${australisId}/routingTable/${routingTableId}`,
        routingTable,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      dispatch(setVOIPAccountDetails(res.data.voipAccount));
    } catch (err) {
      console.error(err);
    } finally {
      //
    }
  };

export const deleteRingGroup =
  (
    token: string,
    accountId: string,
    australisId: string,
    routingTableId: string
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const res = await HTTP.post(
        `/voip/account/${accountId}/user/${australisId}/routingTableTemp/${routingTableId}`,
        { delete: true },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      dispatch(setVOIPAccountDetails(res.data.voipAccount));
    } catch (err) {
      console.error(err);
    } finally {
      //
    }
  };

export const updateDeviceSettings =
  (
    token: string,
    accountId: string,
    australisId: string,
    deviceId: string,
    body: any
  ): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();

    try {
      //
      const res = await HTTP.post(
        `/voip/account/${accountId}/user/${australisId}/device/${deviceId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const { device } = res.data;
      const devices = { ...state.account.VOIPAccountDetails.devices };
      devices[deviceId] = { ...device };

      dispatch(
        setVOIPAccountDetails({
          ...state.account.VOIPAccountDetails,
          devices: { ...devices }
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

export const uploadVoicemailGreeting =
  (
    token: string,
    accountId: string,
    australisId: string,
    values: { file: File; name: string }
  ): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();

    try {
      const res = await HTTP.post(
        `/voip/account/${accountId}/user/${australisId}/voicemailGreetings`,
        {
          voicemailGreetingName: values.name,
          defaultVoicemail: false
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (res.data.url) {
        const s3Result = await axios.put(res.data.url, values.file, {
          headers: { 'Content-Type': 'audio/mpeg' }
        });
      }

      const { voicemailGreeting } = res.data;
      const greeting = {};
      greeting[voicemailGreeting.voicemailGreetingId] = voicemailGreeting;

      dispatch(
        setVOIPAccountDetails({
          ...state.account.VOIPAccountDetails,
          voicemailGreetings: {
            ...state.account.VOIPAccountDetails.voicemailGreetings,
            ...greeting
          }
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

export const createForwardingNumber =
  (
    token: string,
    accountId: string,
    australisId: string,
    values: {
      number: string;
      name: string;
      confirmation: boolean;
      australisId: string;
    }
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const res = await HTTP.post(
        `/voip/account/${accountId}/user/${australisId}/forwardingNumber`,
        {
          ...values
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const { data } = res;

      dispatch(setVOIPAccountDetails(data.voipAccount));
    } catch (err) {
      console.error(err);
    }
  };

export const updateForwardingNumber =
  (
    token: string,
    accountId: string,
    australisId: string,
    forwardingNumberId: string,
    values: {
      number: string;
      name: string;
      confirmation: boolean;
      australisId: string;
      delete?: boolean;
    }
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const res = await HTTP.post(
        `/voip/account/${accountId}/user/${australisId}/forwardingNumber/${forwardingNumberId}`,
        {
          ...values
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const { data } = res;

      dispatch(setVOIPAccountDetails(data.voipAccount));
    } catch (err) {
      console.error(err);
    }
  };

export const deleteForwardingNumber =
  (
    token: string,
    accountId: string,
    australisId: string,
    forwardingNumberId: string
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const res = await HTTP.post(
        `/voip/account/${accountId}/user/${australisId}/forwardingNumber/${forwardingNumberId}`,
        {
          delete: true
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const { data } = res;
      console.log(data);
      dispatch(setVOIPAccountDetails(data.voipAccount));
    } catch (err) {
      console.error(err);
    }
  };
