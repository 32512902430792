import axios from 'axios'
import AxiosMockAdapter from 'axios-mock-adapter'

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL })

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((
    error
    // error.response && error.response.data
  ) || 'Something went wrong')
)

export const mock = new AxiosMockAdapter(axiosInstance, { delayResponse: 0 })

export const HTTP = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL })

HTTP.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((
    error.response && error.response.data
  ) || 'Something went wrong')
)
export default axiosInstance
