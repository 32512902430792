/* eslint-disable */
import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Alert,
  AlertTitle,
  IconButton,
  Snackbar,
  ThemeProvider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { RootState } from 'src/store';
import { removeMessage } from './slices/meta';
import './i18n';
import GlobalStyles from './components/GlobalStyles';
import RTL from './components/RTL';
import SettingsDrawer from './components/SettingsDrawer';
import SplashScreen from './components/SplashScreen';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import routes from './routes';
import { createTheme } from './theme';
import { SnackbarMessage } from './types/snackbarMessage';

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  useScrollReset();
  const dispatch = useDispatch();

  const messages = useSelector((state: RootState) => state.meta.messages);

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  const renderMessages = () =>
    messages.map((message: SnackbarMessage) => (
      <Snackbar
        open
        autoHideDuration={message.duration}
        onClose={(event, reason) => {
          if (reason === 'clickaway') return;
          dispatch(removeMessage(message.id));
        }}
        key={message.id}
      >
        <Alert
          severity={message.type}
          action={
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => dispatch(removeMessage(message.id))}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        >
          {message.title ? (
            <AlertTitle style={{ textAlign: 'left' }}>
              {message.title}
            </AlertTitle>
          ) : null}
          {message.body || ''}
        </Alert>
      </Snackbar>
    ));

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <GlobalStyles />
        <SettingsDrawer />
        {auth.isInitialized ? content : <SplashScreen />}
      </RTL>
      {renderMessages()}
    </ThemeProvider>
  );
};

export default App;
