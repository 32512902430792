import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HTTP } from 'src/lib/axios';
import { AppThunk } from 'src/store';
import { Number } from 'src/types/numbers';

export interface NumberState {
  numbers: Array<Number>;
}
const initialState: NumberState = {
  numbers: []
};
const numbersSlice = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    setNumbers: (state, { payload }: PayloadAction<Array<Number>>) => {
      state.numbers = payload;
    }
  }
});

export const { setNumbers } = numbersSlice.actions;

export const getNumbers =
  (token: string): AppThunk =>
  async (dispatch) => {
    try {
      const res = await HTTP.get('/users/numbers/admin', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(setNumbers(res.data.data));
      console.log(res);
    } catch (err) {
      console.log('');
    }
  };

export default numbersSlice.reducer;
