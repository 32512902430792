import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/MyAccount')));
const Numbers = Loadable(lazy(() => import('./pages/dashboard/Numbers')));
const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));
const Calls = Loadable(lazy(() => import('./pages/dashboard/analytics/Calls')));
const CallDetails = Loadable(
  lazy(() => import('./pages/dashboard/analytics/CallDetails'))
);
const AccountDetails = Loadable(
  lazy(() => import('./pages/dashboard/AccountDetails'))
);
const AccountsList = Loadable(
  lazy(() => import('./pages/dashboard/AccountList'))
);
const InvoiceDetails = Loadable(
  lazy(() => import('./pages/dashboard/InvoiceDetails'))
);
const InvoiceList = Loadable(
  lazy(() => import('./pages/dashboard/InvoiceList'))
);
const OrderDetails = Loadable(
  lazy(() => import('./pages/dashboard/OrderDetails'))
);
const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const ProductCreate = Loadable(
  lazy(() => import('./pages/dashboard/ProductCreate'))
);
const ProductList = Loadable(
  lazy(() => import('./pages/dashboard/ProductList'))
);
const UserList = Loadable(lazy(() => import('./pages/dashboard/UserList')));
const UserDetails = Loadable(
  lazy(() => import('./pages/dashboard/UserDetails'))
);
const NumberDetails = Loadable(
  lazy(() => import('./pages/dashboard/NumberDetails'))
);

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import('./pages/AuthorizationRequired'))
);
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      }
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'numbers',
        element: <Numbers />
      },
      {
        path: 'analytics',
        children: [
          { path: '/', element: <Analytics /> },
          {
            path: 'calls',
            children: [
              {
                path: '/',
                element: <Calls />
              },
              {
                path: ':callId',
                element: <CallDetails />
              }
            ]
          }
        ]
      },
      {
        path: 'accounts',
        children: [
          {
            path: '/',
            element: <AccountsList />
          },
          {
            path: ':accountId',
            element: <AccountDetails />
          },
          {
            path: ':accountId/numbers/:number',
            element: <NumberDetails />
          },
          {
            path: ':accountId/users',
            element: <UserList />
          },
          {
            path: ':accountId/users/:australisId',
            element: <UserDetails />
          }
        ]
      },
      {
        path: 'invoices',
        children: [
          {
            path: '/',
            element: <InvoiceList />
          },
          {
            path: ':invoiceId',
            element: <InvoiceDetails />
          }
        ]
      },
      {
        path: 'orders',
        children: [
          {
            path: '/',
            element: <OrderList />
          },
          {
            path: ':orderId',
            element: <OrderDetails />
          }
        ]
      },
      {
        path: 'products',
        children: [
          {
            path: '/',
            element: <ProductList />
          },
          {
            path: 'new',
            element: <ProductCreate />
          }
        ]
      }
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard/" />
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
