import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SnackbarMessage } from '../types/snackbarMessage'

export interface MetaState {
  messages: Array<any>
}
const initialState: MetaState = {
  messages: []
}
const metaSlice = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    addMessage: (state, { payload }: PayloadAction<SnackbarMessage>) => {
      state.messages.push(payload)
    },
    removeMessage: (state, { payload }: PayloadAction<string>) => {
      const index = state.messages.findIndex((message) => (message.id === payload))
      const clone = [...state.messages]
      if (index > -1) {
        clone.splice(index, 1)
      }
      state.messages = clone
    },
    clearMessages: (state) => {
      state.messages = []
    },
  }
})

export const {
  addMessage, removeMessage, clearMessages
} = metaSlice.actions
export default metaSlice.reducer
export const metaSelector = (state: { metaStore: MetaState }) => state.metaStore
